import { createContext, useContext, useMemo, useState } from "react";
import Account from "../popups/Account";
import Password from "../popups/Password";
import AdminUser from "../popups/AdminUser";
import AdminNode from "../popups/AdminNode";
import AdminUserSearch from "../popups/AdminUserSearch";
import AkSttnSearch from "../popups/AkSttnSearch";
import AdminDevices from "../popups/AdminDevices";
import AdminDevice from "../popups/AdminDevice";
import Airdome from "../popups/Airdome";
import LdltSetup from "../popups/LdltSetup";
import ChgrSetup from "../popups/ChgrSetup";
import HtbcSetup from "../popups/HtbcSetup";
import UvldSetup from "../popups/UvldSetup";
import FdldSetup from "../popups/FdldSetup";
import ArkrSetup from "../popups/ArkrSetup";
import ArdmSetup from "../popups/ArdmSetup";
import WthrSetup from "../popups/WthrSetup";
import WthrCoodSearch from "../popups/WthrCoodSearch";
import AwsStnSearch from "../popups/AwsStnSearch";
import AwssSetup from "../popups/AwssSetup";
import AttachFiles from "../popups/AttachFiles";
import { useAuth } from "./AuthContext";

const DialogContext = createContext();

export const useDialogs = () => {
  return useContext(DialogContext);
};

export const DialogProvider = ({ children }) => {
  const { user } = useAuth();
  const isAdmin = user?.roles?.find(role => role === 'ROLE_ADMIN');

  const [dialog, setDialog] = useState({
    /* 내정보 */
    account: {
      open: false
    },
    /* 내 비밀번호 */
    password: {
      open: false
    },
    /* 사용자(관리자) */
    adminUser: {
      open: false,
      title: '',
      userId: '',
      callback: null
    },
    /* 시설물(관리자) */
    adminNode: {
      open: false,
      title: '',
      nodeId: '',
      callback: null
    },
    /* 사용자검색 (관리자) */
    adminUserSearch: {
      open: false,
      callback: null
    },
    /* 디바이스목록(관리자) */
    adminDevices: {
      open: false,
      nodeId: '',
      nodeNm: '',
      callback: null
    },
    /* 디바이스등록/수정(관리자) */
    adminDevice: {
      open: false,
      title: '',
      nodeId: '',
      nodeNm: '',
      dvcId: '',
      callback: null
    },

    /* 측정소검색 (관리자) */
    akSttnSearch: {
      open: false,
      node: {},
      dvc: {},
      callback: null
    },

    /* 좌표검색 */
    wthrCoodSearch: {
      open: false,
      node: {},
      dvc: {},
      callback: null
    },

    /* 기상청관측소 검색 */
    awsStnSearch: {
      open: false,
      node: {},
      dvc: {},
      callback: null
    },

    /*첨부파일*/
    attachFiles: {
      open: false,
      title: '첨부파일',
      node: {},
      dvc: {},
      callback: null
    },

    /* 에어돔 */
    airdome: {
      open: false,
      title: '',
      node: {},
      dvcs: [],
      callback: null
    },

    /* LED조명 설정 */
    ldltSetup: {
      open: false,
      node: {},
      dvc: {}
    },

    /* 충전기 설정 */
    chgrSetup: {
      open: false,
      node: {},
      dvc: {}
    },
    
    /* 온열벤치 설정 */
    htbcSetup: {
      open: false,
      node: {},
      dvc: {}
    },

    /* UVC LED 설정 */
    uvldSetup: {
      open: false,
      node: {},
      dvc: {}
    },

    /* RGB LED 설정 */
    fdldSetup: {
      open: false,
      node: {},
      dvc: {}
    },

    /* 에어코리아 설정 */
    arkrSetup: {
      open: false,
      node: {},
      dvc: {}
    },

    /* 기상청 설정 */
    wthrSetup: {
      open: false,
      node: {},
      dvc: {}
    },

    /* 기상청 설정 */
    awssSetup: {
      open: false,
      node: {},
      dvc: {}
    },

    /* 에어돔 설정 */
    ardmSetup: {
      open: false,
      node: {},
      dvc: {}
    },
  });

  const openDialog = (id, args) => {
    setDialog({...dialog, [id]: {
      ...dialog[id], ...args, open: true}});
  };

  const closeDialog = (id, args) => {
    setDialog({...dialog, [id]: {
      ...dialog[id], open: false
    }});

    if (args && dialog[id].callback) {
      dialog[id].callback(args);
    }
  };

  const getDialog = (id) => {
    return dialog[id];
  };

  const closeDialogAll = () => {
    Object.keys(dialog).forEach(id => {
      if (dialog[id].open) {
        closeDialog(id);
      }
    })
  };

  const value = useMemo(
    () => ({
      openDialog,
      closeDialog,
      closeDialogAll,
      getDialog
    }),
    [ openDialog, closeDialog, closeDialogAll ]
  );

  return (
    <DialogContext.Provider value={value}>
      {children}
      { isAdmin && <>
        <AdminUser open={dialog.adminUser.open} handleClose={(args) => closeDialog('adminUser', args)} title={dialog.adminUser.title} userId={dialog.adminUser.userId} />
        <AdminNode open={dialog.adminNode.open} handleClose={(args) => closeDialog('adminNode', args)} title={dialog.adminNode.title} nodeId={dialog.adminNode.nodeId} />
        <AdminUserSearch open={dialog.adminUserSearch.open} handleClose={args => closeDialog('adminUserSearch', args)} />
        <AdminDevices open={dialog.adminDevices.open} handleClose={args => closeDialog('adminDevices', args)} nodeId={dialog.adminDevices.nodeId} nodeNm={dialog.adminDevices.nodeNm} />
        <AdminDevice open={dialog.adminDevice.open} handleClose={args => closeDialog('adminDevice', args)} title={dialog.adminDevice.title} nodeId={dialog.adminDevice.nodeId} nodeNm={dialog.adminDevice.nodeNm} dvcId={dialog.adminDevice.dvcId} />
      </>}
      <Account open={dialog.account.open} handleClose={() => closeDialog('account')} />
      <Password open={dialog.password.open} handleClose={() => closeDialog('password')} />
      <AkSttnSearch open={dialog.akSttnSearch.open} handleClose={args => closeDialog('akSttnSearch', args)} node={dialog.akSttnSearch.node} dvc={dialog.akSttnSearch.dvc} />
      <WthrCoodSearch open={dialog.wthrCoodSearch.open} handleClose={args => closeDialog('wthrCoodSearch', args)} node={dialog.wthrCoodSearch.node} dvc={dialog.wthrCoodSearch.dvc} />
      <AwsStnSearch open={dialog.awsStnSearch.open} handleClose={args => closeDialog('awsStnSearch', args)} node={dialog.awsStnSearch.node} dvc={dialog.awsStnSearch.dvc} />
      <AttachFiles open={dialog.attachFiles.open} handleClose={args => closeDialog('attachFiles', args)} title={dialog.attachFiles.title} node={dialog.attachFiles.node} dvc={dialog.attachFiles.dvc} />
      
      <Airdome open={dialog.airdome.open} handleClose={args => closeDialog('airdome', args)} title={dialog.airdome.title} node={dialog.airdome.node} dvcs={dialog.airdome.dvcs} />

      <LdltSetup open={dialog.ldltSetup.open} handleClose={args => closeDialog('ldltSetup', args)} node={dialog.ldltSetup.node} dvc={dialog.ldltSetup.dvc} />
      <ChgrSetup open={dialog.chgrSetup.open} handleClose={args => closeDialog('chgrSetup', args)} node={dialog.chgrSetup.node} dvc={dialog.chgrSetup.dvc} />
      <HtbcSetup open={dialog.htbcSetup.open} handleClose={args => closeDialog('htbcSetup', args)} node={dialog.htbcSetup.node} dvc={dialog.htbcSetup.dvc} />
      <UvldSetup open={dialog.uvldSetup.open} handleClose={args => closeDialog('uvldSetup', args)} node={dialog.uvldSetup.node} dvc={dialog.uvldSetup.dvc} />
      <FdldSetup open={dialog.fdldSetup.open} handleClose={args => closeDialog('fdldSetup', args)} node={dialog.fdldSetup.node} dvc={dialog.fdldSetup.dvc} />
      <ArkrSetup open={dialog.arkrSetup.open} handleClose={args => closeDialog('arkrSetup', args)} node={dialog.arkrSetup.node} dvc={dialog.arkrSetup.dvc} />
      <WthrSetup open={dialog.wthrSetup.open} handleClose={args => closeDialog('wthrSetup', args)} node={dialog.wthrSetup.node} dvc={dialog.wthrSetup.dvc} />
      <AwssSetup open={dialog.awssSetup.open} handleClose={args => closeDialog('awssSetup', args)} node={dialog.awssSetup.node} dvc={dialog.awssSetup.dvc} />
      <ArdmSetup open={dialog.ardmSetup.open} handleClose={args => closeDialog('ardmSetup', args)} node={dialog.ardmSetup.node} dvc={dialog.ardmSetup.dvc} />
    </DialogContext.Provider>
  )
};