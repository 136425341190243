import { useEffect, useState } from "react";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import airdomeDefault from '../assets/images/airdomeDefault.png';
import '../assets/styles/app.css';
import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

const AirdomIconImage = (props) => {
  const { api } = useUtils();
  const { showMessage } = useSnackbar();
  const [ imgSrc, setImgSrc ] = useState(null);
  const [ atchFileId, setAtchFileId ] = useState(null);
  const oprt = !!props.oprt;

  useEffect(() => {
    api.post(`/api/files`, {
      atchId: props.atchId
    })
      .then(response => {
        const atchFileList = response.data?.data;
        if (atchFileList != null && atchFileList.length > 0) {
          setAtchFileId(atchFileList[0].atchFileId);
        } else {
          setAtchFileId(null);
        }
        
      })
      .catch((e) => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error');
      });
  }, [props.atchId]);

  useEffect(() => {
    if (!!atchFileId) {
      api.get(`/api/files/download/${atchFileId}`, {
        responseType: 'blob'  // Blob 형식으로 응답을 받음
      })
      .then(response => {
        // 응답으로 받은 Blob 데이터를 사용해 파일을 생성
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        setImgSrc(downloadUrl);
        // window.URL.revokeObjectURL(downloadUrl);
      })
      .catch (e => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error')
      });
    } else {
      setImgSrc(null);
    }
  }, [atchFileId]);

  return (
    <>
      <img src={imgSrc ? imgSrc : airdomeDefault} style={{maxWidth: 260, maxHeight: 150}} className={`ardmIcon ${oprt ? 'blink' : 'grayscale'}`} />
      {oprt ? <Typography className="ardmOprt" variant="h6" color="primary">운전중</Typography>
            : <Typography className="ardmStop" variant="h6" color={grey[700]}>정지중</Typography>}
    </>
  )
};

export default AirdomIconImage;