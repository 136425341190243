import { useEffect, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, Grid, IconButton, InputAdornment } from "@mui/material";
import { isNotEmpty } from "../utils/validations";
import PodoDialogTitle from "../components/PodoDialogTitle";
import PodoDialogContent from "../components/PodoDialogContent";
import DraggableHeader from "../components/DraggableHeader";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useForm } from "../hooks/useForm";
import HelpTooltip from "../components/HelpTooltip";
import CompactTextField from "../components/CompactTextField";
import { Search } from "@mui/icons-material";
import { useDialogs } from "../contexts/DialogContext";

const ArkrSetup = (props) => {
  const { openDialog } = useDialogs();
  const { api } = useUtils();
  const { showMessage } = useSnackbar();
  const node = props.node;
  const dvc = props.dvc;
  const data = dvc.data;

  const { values, handleChange, handleChangeValues, resetForm, touched, errors, isValid } = useForm({}, []);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    if (props.open) {
      resetForm({...data})
    }
  }, [props.open]);

  //적용
  const handleApply = () => {
    const param = {
      sttn: values.sttn
    };

    setIsLoading(true);
    api.post(`/api/devices/${dvc.dvcId}/update`, param).then(response => {
      showMessage(response?.message || '적용되었습니다.');
      props.handleClose();
      setIsLoading(false);
    }).catch(e => {
      if (e.response?.status === 401) props.handleClose();
      showMessage(`[${e.response?.status}] ${e.message}`, 'error');
      setIsLoading(false);
    });
  };

  const handleAkSttnSearch = () => {
    openDialog('akSttnSearch', {
      node: node,
      dvc: dvc,
      callback: (sttn) => handleChangeValues({sttn: sttn?.stationName })
    });
  }

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader}>
      <PodoDialogTitle text={'설정: ' + dvc.dvcNm} onClose={() => props.handleClose()}/>
      <PodoDialogContent>
        <Grid container spacing={2} justifyContent="space-evenly">
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <CompactTextField name="sttn" label="에어코리아측정소"
              value={values.sttn || ''}
              onChange={handleChange}
              size='small'
              sx={{ maxWidth: 150 }}
              InputProps={{ endAdornment:
                <InputAdornment position="end" sx={{}}>
                  <IconButton edge="end" onClick={handleAkSttnSearch} title="검색">
                    <Search fontSize="small"/>
                  </IconButton>
                </InputAdornment>
              }}
              margin="dense"
              fullWidth
              disabled={false}
              autoComplete="off" />
            <HelpTooltip>지정한 에어코리아 측정소의 데이터를 수집합니다.</HelpTooltip>
          </Grid>
        </Grid>
      </PodoDialogContent>
      <DialogActions>
        <Button variant="contained" disabled={!isValid || isLoading} onClick={handleApply}>
        {isLoading ? <CircularProgress size={24} color="inherit" /> : '적용'}
        </Button>
        <Button variant="contained" color="neutral" onClick={props.handleClose}>취소</Button>
      </DialogActions>
    </Dialog>
  )
};

export default ArkrSetup;