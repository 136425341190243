import { Container, Grid, Typography } from "@mui/material";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useEffect, useState } from "react";

import NodeAirdome from "../components/NodeAirdome";
import { useInterval } from "../contexts/useInterval";


const Nodes = () => {
  const { api } = useUtils();
  const { showMessage } = useSnackbar();
  const [ list, setList ] = useState([]);
  const [ isLoading, setIsLoading ] = useState([]);

  const handleRead = () => {
    api.post(`/api/nodes`, {})
      .then(response => {
        const nodeList = response.data?.data;
        if (!!nodeList) {
          setList(nodeList.sort((a, b) => { return a.ordNo - b.ordNo}));
        } else {
          setList([]);  
        }
        setIsLoading(false);
      })
      .catch(e => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error');
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleRead();
  }, []);

  useInterval(() => {
    handleRead();
  }, 5 * 1000)

  const displayNodes = (list) => {
    if (list != null && list.length > 0) {
      return list.map((node, index) => {
        switch (node.nodeTypeCd) {
          case "ADM": return (<NodeAirdome key={index} node={node} />)
            
        }
      });

    } else {
      return (
        <Grid item xs={12} >
          <Typography variant="h5">
            등록된 시설물이 없습니다.
          </Typography>
        </Grid>
      );
    }
  };

  return (
    <Container>
      <Grid container spacing={2} sx={{minWidth: 1280}}>
      { displayNodes(list)}
      </Grid>
    </Container>
  )
};

export default Nodes;